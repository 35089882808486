import { useEffect, useState } from 'react';
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import { Flex, Space, Input, Table, Button, Pagination } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import LoadingOrErrorPanel from 'components/LoadingOrErrorPanel';
import Mask from "utils/masks"
import ContatosService from 'services/ContatosService';
import ClientesService from 'services/ClientesService';
import LayoutUtils from 'utils/layout';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import { useWindowSize } from 'store/useWindowSize';
import { usePagination } from 'store/usePagination';

function ListagemContatos () {
    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
    const [contatoSelecionado, setContatoSelecionado] = useState({});
    const [empresas, setEmpresas] = useState([]);
    const {alturaTotalTela} = useWindowSize();
    const { Search } = Input;
    

    const ListarContatos = async (paginaAtual, tamanhoPagina, filtro, skipToken, filtroPorColuna) => {
        const filtroEstruturado = estruturarFiltro(filtro, filtroPorColuna);
        debugger;
        const dados = await ContatosService.Listar(paginaAtual, tamanhoPagina, filtroEstruturado)
        if (dados == null) return;
        return {
            ...dados,
            contatos: dados.contatos.map(contato => ({
              ...contato,
              status: obterTextoStatusContato(contato.status)
            }))
        };
    };

    const obterTextoStatusContato = (status) => {
        return status == CONSTANTES_AMBIENTE.TIPOS_STATUS_CONTATOS.ATIVA ? 
            'Ativa' : 'Inativa';
    };

    const ListarResumoClientes = async () => {
        const empresas = await ClientesService.ListarResumoClientes();
        setEmpresas(empresas);
    };

    useEffect(() => {
        ListarResumoClientes();
    }, []);

    const {
        dados: contatos,
        paginacaoOpcoes,
        paginacaoLoading,
        paginacaoErro,
        filtroAplicado,
        filtroPorColuna,
        handleAlteracaoDePaginacao,
        handleFiltro,
        handleTableChange
    } = usePagination(ListarContatos, 'contatos', true, 20)

    const LayoutHeights = {
        header: 92,
        footer: 90,
        containerPadding: 32,
        innerHeader: 60,
        tableTitle: 60
    }

    const paginacaoOnChange = (paginaAtual, tamanhoPagina) =>{
        setLinhasSelecionadas([]);
        handleAlteracaoDePaginacao(paginaAtual, tamanhoPagina);
    }

    const rowSelectionOnChange = (newSelectedRowKeys, selectedRows) => {
        setLinhasSelecionadas(newSelectedRowKeys);
        setContatoSelecionado(selectedRows[0]);
    };

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: linhasSelecionadas,
        onChange: rowSelectionOnChange
    };

    const estruturarFiltro = (filtroPrincipal, filtroTabela) => {
        let filtroEstruturado = '';

        if (filtroPrincipal) {
            filtroEstruturado += `Principal:${filtroPrincipal}-`;
        }
        
        if (Object.values(filtroTabela).some( x => x != null)) {
            Object.entries(filtroTabela).some(([key,value]) => {
                if (value)
                {
                    filtroEstruturado += `Tabela:${value}`;
                    return true;
                } 
            })
        }

        return filtroEstruturado;
    }
      
    const colunas = [
        {
          title: 'Nome Completo',
          dataIndex: 'nomeCompleto',
          key: 'nomeCompleto',
          width: '200px',
          ellipsis: true,
          sorter: (a, b) => a.nomeCompleto.localeCompare(b.nomeCompleto)
        },
        {
          title: 'Empresa',
          dataIndex: 'empresa',
          key: 'empresa',
          ellipsis: true,
          sorter: (a, b) => a.empresa.localeCompare(b.empresa),
          filters: empresas.map(empresa => ({ text: empresa.nomeFantasia, value: empresa.nomeFantasia })),
          filterMultiple: false,
          filteredValue: filtroPorColuna?.empresa
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          key: 'email',
          ellipsis: true,
          sorter: (a, b) => a.email.localeCompare(b.email)
        },
        {
          title: 'Telefone',
          dataIndex: 'telefone',
          key: 'telefone',
          width: '150px',
          render: (telefone) => telefone ? Mask(telefone, 'phone') : '',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width: '100px',
          sorter: (a, b) => obterTextoStatusContato(a.status).localeCompare(obterTextoStatusContato(b.status)),
          filters: [
            {
              text: 'Ativo',
              value: obterTextoStatusContato(CONSTANTES_AMBIENTE.TIPOS_STATUS_CONTATOS.ATIVO),
            },
            {
              text: 'Inativo',
              value: obterTextoStatusContato(CONSTANTES_AMBIENTE.TIPOS_STATUS_CONTATOS.INATIVO),
            },
          ],
          filteredValue: filtroPorColuna?.status
        }
    ];

      
    return(
    <MasterPage useFullWidth={true}>
        <Space direction="vertical" style={{ width: '100%' }}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            </Space>
            <LoadingOrErrorPanel
                loading={paginacaoLoading}
                hasError={paginacaoErro.temErro}
                mensagemErro={paginacaoErro.mensagens}
                >
                    <Flex justify='flex-start' align= 'center' style={{ height: '40px', background: '#f8f9fa', boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", margin: "10px 0", borderRadius:"5px", padding:"3px 10px" }}>
                        <Button type="text" onClick={() => history.push('/usuarios/novo')} style={{ marginRight: 8 }}>
                            <PlusOutlined />Novo Contato
                        </Button>
                        {linhasSelecionadas.length > 0 && contatoSelecionado && (
                            <>
                            {contatoSelecionado.status === 'Ativo' ? (
                                <>
                                <Button type="text" style={{ marginRight: 8 }}>
                                    <EditOutlined />Editar
                                </Button>
                                <Button type="text" danger>
                                <ExclamationCircleOutlined />Desativar
                                </Button>
                                </>
                            ) : (
                                <Button type="text" solid>
                                <CheckCircleOutlined />Ativar
                                </Button>
                            )}
                            </>
                        )}
                    </Flex>
                    <Table
                        rowKey="contatoId"
                        dataSource={contatos}
                        columns={colunas}
                        rowSelection={rowSelection} 
                        pagination={false}
                        size='small'
                        onChange={handleTableChange}
                        scroll={ contatos.length > 0 ? { y: LayoutUtils.ObterAlturaDisponivelEmTela(Object.values(LayoutHeights), alturaTotalTela) } : undefined }
                        title={() =>
                            <Flex justify='space-between' align= 'center' style={{ height: '44px' }}>
                            <h3 style={{ fontSize: '28px' }}>Contatos</h3>
                                <Search
                                    placeholder={"Filtrar por nome ou e-mail"}
                                    allowClear
                                    style={{width: 235}}
                                    onSearch={handleFiltro}
                                    defaultValue={filtroAplicado}
                                />
                            </Flex>
                        }
                        footer={() => 
                            <Pagination 
                                defaultCurrent={1}
                                simple={{ readOnly: true }}
                                total={paginacaoOpcoes.total}
                                showTotal={(total) => `Total: ${total}`}
                                pageSize={paginacaoOpcoes.tamanhoPagina}
                                onChange={paginacaoOnChange}
                                disabled={paginacaoLoading}
                                showSizeChanger={false}
                                current={paginacaoOpcoes.paginaAtual}
                                locale={{ items_per_page: '/ página' }}
                            />
                        }
                    />
            </LoadingOrErrorPanel>
        </Space>
    </MasterPage>)
}

export default ListagemContatos;