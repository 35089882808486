import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import ReactAI from 'react-appinsights';

import Home from 'EstruturaAntiga/pages/Home';
import Error404 from 'EstruturaAntiga/pages/errorPages/404';
import Error401 from 'EstruturaAntiga/pages/errorPages/401';
import Error403 from 'EstruturaAntiga/pages/errorPages/403';
import Error503 from 'EstruturaAntiga/pages/errorPages/503';
import ClearCache from 'EstruturaAntiga/ClearCache';
import Loading from 'EstruturaAntiga/components/loading/Loading';
import { createRouters } from 'EstruturaAntiga/router/routerHelpers';
import { EsqueciMinhaSenha } from 'EstruturaAntiga/pages/EsqueciMinhaSenha';
import { Provisionamento } from 'EstruturaAntiga/pages/Provisionamento';

import { AuthProvider } from 'context/AuthContext';
import { MenuProvider } from 'context/MenuContext';
import { RoteiroProvider } from 'context/RoteiroContext';
import PesquisaSatisfacao from 'pages/Atendimentos/PesquisaSatisfacao';
import PesquisaSatisfacaoConclusao from 'pages/Atendimentos/PesquisaSatisfacao/Components/PesquisaSatisfacaoConclusao';
import PublicRoute from './PublicRoute';
import EnviarDadosNavegacao from 'EstruturaAntiga/actions/Navegacao/EnviarDadosNavegacao';
ReactAI.init({ instrumentationKey: '067b3f02-0222-4a8c-852a-e4f571da0518' });

class AppRoutes extends React.Component {
    render(){
        var history = createBrowserHistory();
        const contact = JSON.parse(sessionStorage.getItem('contact'));
        if (contact) {
            history.listen(function (location) {
                EnviarDadosNavegacao(location.pathname);
                const gtag = window.gtag;
                gtag('config', 'UA-44364102-8', {
                    'page_title': document.title,
                    'page_location': window.location.href,
                    'page_path': location.pathname,
                    'contact_id': contact && contact.idContact
                });
            });
        }
        return (
            <Router key={Math.random()} history={history}>
                <Switch>
                    <Route path="/provisionamento" component={Provisionamento} />
                    <Route path="/pesquisa-satisfacao" component={PesquisaSatisfacao}/>
                    <Route path="/pesquisa-satisfacao-conclusao" component={PesquisaSatisfacaoConclusao} />
                    <Route path="/401" component={Error401} />
                    <Route path="/403" component={Error403} />
                    <Route path="/404" component={Error404} />
                    <Route path="/503" component={Error503} />
                    <Redirect from="/null" to="/" />
                    <AuthProvider history={history}>
                        <MenuProvider>
                            <RoteiroProvider>
                                <ClearCache/>
                                <Loading/>
                                <Switch>
                                    {createRouters()}
                                    <PublicRoute path="/" exact><Home history={history}/></PublicRoute>
                                    <PublicRoute path="/login"><Redirect to="/"/></PublicRoute> 
                                    <PublicRoute path="/esqueci-minha-senha"><EsqueciMinhaSenha/></PublicRoute>
                                    <Route component={Error404} />
                                </Switch>
                            </RoteiroProvider>
                        </MenuProvider>
                    </AuthProvider>
                </Switch>
            </Router>
        );
    }
};

export default AppRoutes;
