import React from 'react';
import { navigation } from './routerNavigation'
import { Redirect } from 'react-router-dom'
import Uuid from 'EstruturaAntiga/assets/uuid'
import store from '../store/configureStore'
import { withAdalLoginApi } from '../adalConfig'

/* Pages */
import Error401 from '../pages/errorPages/401'
import Error403 from '../pages/errorPages/403'
import Error404 from '../pages/errorPages/404'
import Error503 from '../pages/errorPages/503'
import TemAcessoAosProfiles from 'EstruturaAntiga/permissions/checkPermissionV2';
import CONSTANTES_AMBIENTE from '../../constants/constantesAmbiente';
import PrivateRoute from 'routers/PrivateRoute';

function redirectRules(){
    //Se não possui perfil de acesso ao site
    if(store.getState().router.error === '401') return <Error401 />;

    //Se não possui acesso a pagina especifica
    if(store.getState().router.error === '403') return <Error403 />;

    //Não encontrado
    if(store.getState().router.error === '404') return <Error404 />;

    //Se der erro no servidor
    if(store.getState().router.error === '503') return <Error503 />;

    return false
}

export function userRulesV2(permissionRequired, unauthorized, withoutBHS = false){
    const rules = redirectRules();

    if(!rules){
        if(permissionRequired){
            if(!TemAcessoAosProfiles(permissionRequired, withoutBHS)) return  <Error403 /> ;
        }

        if(unauthorized) return  <Error403 /> ;

        //Se tiver algum termo de aceite pendente
        if(store.getState().termos.listaTermos.length) return <Redirect to="/TermosDeAceite" />
    }
    return rules;
}

export function termsRules(){
    const rules = redirectRules();

    if(rules === false){
        //Se não tiver nenhum termo de aceite pendente
        if(!store.getState().termos.listaTermos.length) return <Redirect to="/" />
    }
    return rules;
}

export function termRules(){
    const rules = redirectRules();

    if(rules === false){
        //Se não tiver nenhum termo selecionado
        if(store.getState().termos.selectedTerm === null) return <Redirect to="/TermosDeAceite" />
    }
    return rules;
}

function createRouterChild(el){
    const PageComponent = withAdalLoginApi(el.render)
    var child = [];
    
    child.push(<PrivateRoute exact key={ Uuid() } path={ el.path } >
        <PageComponent />
    </PrivateRoute>);

    if(Array.isArray(el.child)){
        el.child.forEach((el) => {
            child = [...child, ...createRouterChild(el)]
        });
    }

    return child;
}

export function createRouters(){
    var routers = [];

    navigation.forEach((el) => {
        routers = [...routers, ...createRouterChild(el)]
    });

    return routers;
}

export function isTenantNCE() {
    const tenant = JSON.parse(sessionStorage.getItem('tenant'));
    if(tenant && tenant.modeloOferta && (tenant.modeloOferta == CONSTANTES_AMBIENTE.CONFIGURACOES.TENANT_MODELO_OFERTA_NCE)){
        return true;
    }
    return false;
}

export function isTenantLegacy() {
    const tenant = JSON.parse(sessionStorage.getItem('tenant'));
    if(tenant && tenant.modeloOferta && (tenant.modeloOferta == CONSTANTES_AMBIENTE.CONFIGURACOES.TENANT_MODELO_OFERTA_LEGACY)){
        return true;
    }
    return false;
}