import PortalClienteService from "services/bases/PortalClienteService";

class TenantsService extends PortalClienteService{
    async ListarPorDireito(direitoid){
        return await this.get(`tenants/ListarTenantsPorDireito?direitoId=${direitoid}`)
    }
    async ObterTenant(tenantId){
        return await this.get(`dynamics/Tenant/${tenantId}`)
    }
}




export default new TenantsService();