import getFoto from "EstruturaAntiga/actions/account/getFoto";
import authenticationBHSToken from "EstruturaAntiga/actions/authenticationBHSToken";
import getUsuarioAutenticadoAAD from "EstruturaAntiga/actions/usuarios/getUsuarioAutenticadoAAD";
import ContatosPortalDbService from "services/ContatosPortalDbService";
import DireitosService from "services/DireitosService";
import TenantsPortalDbService from "services/TenantsPortalDbService";
import TenantsService from "services/TenantsService";
import photoDefault from "../assets/images/user-default.svg";
import { mapSessionStorageTenant } from "services/mappers/TenantMapper";

const AuthenticationService = {
    /**
     * Obtém o token armazenado no cache (sessionStorage) e verifica se ainda é válido.
     *
     * @returns {[boolean, object|null]} Um array onde:
     * - O primeiro elemento (`boolean`) indica se o token é válido.
     * - O segundo elemento (`object|null`) contém os dados do contato armazenado, ou `null` se o token for inválido.
     */
    ObterTokenBhsEmCache(){
        let cachedContact = JSON.parse(sessionStorage.getItem("contact"));
        let token = cachedContact?.tokenData;
        if(token && new Date(token?.expiration) > new Date(Date.now() + 2 * 60 * 1000)){
            return [true, cachedContact];
        }
        return [false, null];
    },
    
    /**
     * Obtém e retorna um novo token de autenticação do serviço BHS.
     *
     * @returns {Promise<{ sucesso: boolean, value: object|null, error: object|null }>}
     * Um objeto contendo:
     * - `sucesso` (`boolean`): Indica se a obtenção do token foi bem-sucedida.
     * - `value` (`object|null`): O token retornado pelo serviço ou `null` em caso de erro.
     * - `error` (`object|null`): Um objeto de erro em caso de falha, ou `null` se bem-sucedido.
     */
    async ObterNovoTokenBhs() {
        let error = null;
        let sucesso = false;
        const authData = await authenticationBHSToken().then((response)=>{
            if(response?.erros){
                error = {statuscode: 500, msg: response?.erros}
                return null;
            }
            sucesso = true;
            return response;
        }).catch(error =>{
            error = {statuscode: 401, msg: error}
            return null;
        })

        return [sucesso, authData, error];
    },
    /**
     * Atualiza os dados relacionados ao token, verificando primeiro se há um token válido no cache.
     * Se não houver, tenta obter um novo token e armazená-lo no sessionStorage.
     *
     * @returns {Promise<{ sucesso: boolean, value: object|null, error: object|null }>} 
     * Um objeto contendo:
     * - `sucesso` (`boolean`): Indica se a operação foi bem-sucedida.
     * - `value` (`object|null`): O token atualizado ou `null` caso não tenha sido obtido.
     * - `error` (`object|null`): Um objeto de erro caso a operação falhe, ou `null` se bem-sucedida.
     */
    async AtualizarEmCacheDadosRelacionadosAoToken(){
        const [cachedTokenSucesso, cachedToken] = this.ObterTokenBhsEmCache();
        if(cachedTokenSucesso){
            return [true, cachedToken, null];
        }
        let [sucesso, token, error] = await this.ObterNovoTokenBhs();
        if(sucesso){
            sessionStorage.setItem("contact", JSON.stringify(token));
        }
        return [sucesso, token, error];
    },
    async ObterContatoDoUsuario(authData){
        let error = null;
        let sucesso = false;
        let contatoResponse = await ContatosPortalDbService.ObterContato()
        .then(response=>{
            
                if(response.error || response.erros){
                    error = response.error || response.erros;
                    return null;
                }
                let contatoNovo = {...response,
                    tokenData: authData.tokenData,
                    message: authData.message,
                    authenticated: authData.authenticated,
                    userPhoto: photoDefault
                }
                sucesso = true;
                return contatoNovo;
            })
            .catch(requestError=>{
                error = {statuscode: requestError?.response?.status, msg: requestError?.response?.data}
                return null;
            });
        return [sucesso, contatoResponse, error]
    },
    async AtualizarEmCacheContatoDoUsuario(authData){
        let [sucesso, contatoResponse, error] = await this.ObterContatoDoUsuario(authData);
        if(sucesso){
            sessionStorage.setItem('contact', JSON.stringify(contatoResponse));
        }
        return [sucesso, contatoResponse, error];
    },
    async ObterDireitoAtualDoUsuario(){
        let error = null;
        let sucesso = false;
        let direitoResponse = await DireitosService.BuscarDireitoAtual().then(response=>{
            if(response?.error || response?.erros){
                error = response.error || response.erros;
                return null;
            }

            sucesso = true;
            return response;
        })
        .catch(requestError=>{
            error = {statuscode: requestError.response.status, msg: requestError.response.data}
            return null;
        });
        return [sucesso, direitoResponse, error]
    },
    /**
     * Atualiza o cache do direito atual do usuário, caso ainda não esteja armazenado ou se for solicitado ignorar o cache.
     *
     * @param {boolean} [ignorarValorEmCache=false] - Se `true`, força a atualização ignorando o cache armazenado.
     * @returns {Promise<{ sucesso: boolean, direito: object|null, erro: object|null }>} 
     * Um objeto contendo:
     * - `sucesso` (`boolean`): Indica se a operação foi bem-sucedida.
     * - `direito` (`object|null`): O direito do usuário, se encontrado.
     * - `erro` (`object|null`): Detalhes do erro, caso ocorra.
     */
    async AtualizarEmCacheDireitoAtualDoUsuario(ignorarValorEmCache = false){
        let cachedDireito = JSON.parse(sessionStorage.getItem("signature"));
        if(cachedDireito != null && cachedDireito != undefined && !ignorarValorEmCache){
            return [true, cachedDireito, null]
        }
        let [sucesso, direitoResponse, error] = await this.ObterDireitoAtualDoUsuario();
        if(sucesso){
            sessionStorage.setItem('signature', JSON.stringify(direitoResponse));
        }
        return [sucesso, direitoResponse, error];
    },
    async ListarTenantsDoUsuario(){
        let error = null;
        let sucesso = false;
        let tenantsResponse = await TenantsPortalDbService.BuscarTenants().then(response=>{
            if(response.error || response.erros){
                error = response.error || response.erros;
                return null;
            }
            
            sucesso = true;
            return response;
        })
        .catch(requestError=>{
            error = {statuscode: requestError.response.status, msg: requestError.response.data}
            return null;   
        });
        return [sucesso, tenantsResponse, error]
    },
    /**
     * Atualiza o cache dos tenants do usuário, caso ainda não estejam armazenados.
     *
     * @returns {Promise<{ sucesso: boolean, tenants: object[]|null, erro: object|null }>} 
     * Um objeto contendo:
     * - `sucesso` (`boolean`): Indica se a operação foi bem-sucedida.
     * - `tenants` (`object[]|null`): Lista de tenants, se encontrados.
     * - `erro` (`object|null`): Detalhes do erro, caso ocorra.
     */
    async AtualizarEmCacheTenantsDoUsuario(){
        let cachedTenants = JSON.parse(sessionStorage.getItem("tenants"));
        if(cachedTenants != null && cachedTenants != undefined){
            return [true, cachedTenants, null]
        }

        let [sucesso, tenantsResponse, error] = await this.ListarTenantsDoUsuario();
        if(sucesso){
            sessionStorage.setItem('tenants', JSON.stringify(tenantsResponse));
        }

        return [sucesso, tenantsResponse, error];
    },
    async ObterTenantAtualDoUsuario(tenantId){
        let error = null;
        let sucesso = false;
        let tenant = await TenantsService.ObterTenant(tenantId)
        .then(response=>{
            if(response.error || response.erros){
                error = response.error || response.erros;
                return null;
            }

            sucesso = true;
            return response;
        })
        .catch(requestError=>{
            error = {statuscode: requestError.response.status, msg: requestError.response.data}
            return null;   
        });
        return [sucesso, mapSessionStorageTenant(tenant), error]
    },
    /**
     * Atualiza o cache do tenant atual do usuário, caso ainda não esteja armazenado.
     *
     * @param {string} tenantId - O ID do tenant a ser atualizado no cache.
     * @returns {Promise<{ sucesso: boolean, tenant: object|null, erro: object|null }>} 
     * Um objeto contendo:
     * - `sucesso` (`boolean`): Indica se a operação foi bem-sucedida.
     * - `tenant` (`object|null`): Dados do tenant, se encontrados.
     * - `erro` (`object|null`): Detalhes do erro, caso ocorra.
     */
    async AtualizarEmCacheTenantAtual(tenantId, ignorarValorEmCache = false){
        let cachedTenant = JSON.parse(sessionStorage.getItem("tenant"));
        if(cachedTenant != null && cachedTenant != undefined && !ignorarValorEmCache){
            return [true, cachedTenant, null]
        }
        if(tenantId == null){
            sessionStorage.setItem('tenant', JSON.stringify({}));
            return [true, {}, null]
        }

        let [sucesso, tenantResponse, error] = await this.ObterTenantAtualDoUsuario(tenantId);
        if(sucesso){
            sessionStorage.setItem('tenant', JSON.stringify(tenantResponse));
        }
        return [sucesso, tenantResponse,error];
    },
    async ObterDadosPerfilUsuario(userData){
        let error = null;
        let sucesso = false;

        let perfilResponse = await Promise.all([
            getUsuarioAutenticadoAAD(userData.profile.upn, userData.profile.tid),
            getFoto()
        ]).then(([usuarioAAD, foto])=>{
            
            sucesso = true;
            return{
                onPremisesSyncEnabled: usuarioAAD?.onPremisesSyncEnabled,
                userPhoto: (!foto || foto.error) ? photoDefault : 'data:image/jpeg;base64,' + foto
            }
        }).catch((responseError)=>{
            error = responseError;
            return null;
        })
        return [sucesso, perfilResponse, error]
    }
    ,
    async AtualizarEmCacheContatoComPerfilDoUsuario(userData){
        let cachedContact = JSON.parse(sessionStorage.getItem("contact"));
        
        let [sucesso, perfil, error] = await this.ObterDadosPerfilUsuario(userData);
        let contatoAtualizado = {
            ...cachedContact,
            onPremisesSyncEnabled: perfil?.onPremisesSyncEnabled,
            userPhoto: perfil.userPhoto
        }
        sessionStorage.setItem("contact", JSON.stringify(contatoAtualizado));

        return [sucesso, contatoAtualizado,error]
    }
}

export default AuthenticationService;