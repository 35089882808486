import postObterEstatisticasOcorrencias from "EstruturaAntiga/actions/dashboard/postObterEstatisticasOcorrencias";
import getChamados from "EstruturaAntiga/actions/chamados/getChamados";
import getVideosFromYoutube from 'EstruturaAntiga/actions/dashboard/getVideosFromYoutube';

import Nuvem from '../../../../assets/icons/nuvem.svg';
import Ouro from '../../../../assets/icons/ouro.svg';
import OnPremise from '../../../../assets/icons/onPremise.svg';
import Outsourcing from '../../../../assets/icons/outsourcing.svg';
import Dispositivos from '../../../../assets/icons/dispositivos.svg';
import Suporte from '../../../../assets/icons/suporte.svg';
import Compliance from '../../../../assets/icons/compliance.svg';
import ServicesJson from '../componentes/servicesContent.json';

const signature = sessionStorage.getItem('signature') ? JSON.parse(sessionStorage.getItem('signature'))?.serviceLineId?.toLowerCase() : '';

export const rdxGetServices = () => {
	const services = ServicesJson.filter(service => {
		if (service.image === 'ouro') service.imageUrl = Ouro;
		else if (service.image === 'nuvem') service.imageUrl = Nuvem;
		else if (service.image === 'onPremise') service.imageUrl = OnPremise;
		else if (service.image === 'outsourcing') service.imageUrl = Outsourcing;
		else if (service.image === 'dispositivos') service.imageUrl = Dispositivos;
		else if (service.image === 'compliance') service.imageUrl = Compliance;
		else if (service.image === 'suporte') service.imageUrl = Suporte;
		else service.imageUrl = '';

		return (service.serviceLine !== signature) && service;
	});

	return dispatch => dispatch({ type: 'DASHBOARD_SERVICES', payload: services });
}

export const rdxPostObterQtdChamadosFechados = () => {
    const params = [1,2];
    return dispatch => (postObterEstatisticasOcorrencias(params).then((obj) => {
        dispatch({ type: 'DASHBOARD_BUSCA_QUANTIDADE_CHAMADOS_FECHADOS', payload: obj })
    }))
}

export const rdxPostObterQtdChamadosAbertos = () => {
    const params = [0];
    return dispatch => (postObterEstatisticasOcorrencias(params).then((obj) => {
        dispatch({ type: 'DASHBOARD_BUSCA_QUANTIDADE_CHAMADOS_ABERTOS', payload: obj })
    }))
}

export const rdxGetChamados = (params) => {
    return dispatch => (getChamados(params).then((obj) => {
        dispatch({ type: 'DASHBOARD_LISTA_CHAMADOS', payload: obj })
    }))
}

export const rdxClearDashboard = (editable) => ({
    type: 'DASHBOARD_CLEAR',
    payload: editable
})

export const rdxGetVideosFromYoutube = () => {
    return dispatch => (getVideosFromYoutube().then((obj) => {
        dispatch({type: 'DASHBOARD_BUSCA_VIDEOS_YOUTUBE', payload: obj})
    }));
}

export const rdxIndicadorClicked = (value) => {
    let isOccurrenceActive = true;
    switch (value) {
        case 1:
        isOccurrenceActive = true;
            break;
        case 2:
        isOccurrenceActive = false;
            break;
        default:
        isOccurrenceActive = true;
            break;
    }

    return dispatch => dispatch({ type: 'INDICADOR_CLICKED', payload: isOccurrenceActive });
}
