import React from 'react';
import { logOut } from "../../actions/logOut";
import { Link } from 'react-router-dom';

import Dados from '../../../assets/icons/dados.svg';
import Senha from '../../../assets/icons/senha.svg';
import Sair from '../../../assets/icons/sair.svg';
import Dropdown from '../../../assets/icons/arrow_down.svg';
import { useAuth } from 'context/AuthContext';

const UserNavigation = () => {
	const {user, direito, contato} = useAuth()

	function renderEditarMeusDados() {
		let onPremisesSyncEnabled = JSON.parse(sessionStorage.getItem('contact'))?.onPremisesSyncEnabled;
		if (!onPremisesSyncEnabled) {
			return (
				<Link className="nav-item" to="/EditarMeusDados">
					<img src={Dados} alt="Atualizar meus dados" />
					<span>Atualizar meus dados</span>
				</Link>
			)
		}
	}

	function renderNavItems() {
		return (
			<nav className="user-nav">
				{renderEditarMeusDados()}

				<Link className="nav-item" to="/AlterarSenha">
					<img src={Senha} alt="Alterar senha" />
					<span>Alterar senha</span>
				</Link>

				<span className="nav-item" onClick={() => logOut()}>
					<img src={Sair} alt="Logout" />
					<span>Sair</span>
				</span>
			</nav>
		)
	}
	return (
		<div>
			<div className="user-container">
				<div className="user-info-container">
					<img src={contato?.userPhoto} className="user-photo" alt="Nome do usuário..." />
					<div>
						<span className="user-first-name">{user?.profile?.given_name}</span>
						<b className="user-signature-name"> {direito?.tenantName || direito?.serviceLineName}</b>
					</div>
					<img className="dropdown" src={Dropdown} alt="Dropdown"/>
				</div>

				<div className="user-nav-container">
					<h5>Configurações</h5>
					{renderNavItems()}
				</div>
			</div>
		</div>
	);
}
export default UserNavigation;
