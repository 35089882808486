import React from 'react';
import LoginBg from '../../../assets/images/login-bg.png';
import Logo from '../../../assets/images/logo.png';

import './style.scss';
import { useAuth } from 'context/AuthContext';

function Home({history}) {
  const { login, logout } = useAuth();

  return (
    <div className="login-page">
      <img src={LoginBg} alt="Login Background" />

      <div className="login-wrapper">
        <div>
          <img src={Logo} alt="BHS" />

          <div className="login-titles">
            <h2>Boas Vindas!</h2>
            <h3>Este é o seu Portal do Cliente</h3>
          </div>

          <div className="login-buttons">
            <button className="login-button" onClick={() => login()}>Entrar</button>
            <button className="forget-password-btn" onClick={() => history.push('/esqueci-minha-senha')}>Esqueci minha senha</button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Home