import React from 'react'
import { useAuth } from 'context/AuthContext';

const UserNavigationMobile = () => {
	const {user, direito, contato, logout} = useAuth()
	
	return (
		<div className="user-nav-mobile">
			<img src={contato?.userPhoto} className="user-photo" alt={user?.profile.given_name + ' ' + user?.profile.family_name} />

			<div className="user-nav-container">
				<span className="user-name">{user?.profile.given_name} {user?.profile.family_name}</span>
				<b> { direito?.tenantName || direito?.entitlementName }</b>
				<span className="user-logout" onClick={() => logout()}><i className="icon icon-logout"></i>Logout</span>
			</div>
		</div>
	);
}

export default UserNavigationMobile
