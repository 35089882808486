import { useAuth } from 'context/AuthContext';
import React from 'react'

const Loading = (props,ref) => {
    const {loading} = useAuth();
    if(props.isLoading || loading){
        return (
            <div className="loading">
                <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
        );
    }
    return null;
};

export default React.forwardRef(Loading)