import React, { Component } from 'react';
import getListarTermosParaAceite from "./EstruturaAntiga/actions/termos/getListarTermosParaAceite";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTermos } from './EstruturaAntiga/pages/termosDeAceite/termoAceiteActions';
import { setLoadingItem, removeLoadingItem } from './EstruturaAntiga/components/loading/loadingActions';
import { errorPage } from './EstruturaAntiga/router/redux/routerActions';
import 'react-notifications/lib/notifications.css';
import './EstruturaAntiga/stylesheets/style.scss';
import './EstruturaAntiga/stylesheets/vendor/icomoon/fonts/icomoon.ttf';
import './EstruturaAntiga/stylesheets/vendor/icomoon/fonts/icomoon.woff';
import './EstruturaAntiga/stylesheets/vendor/icomoon/fonts/icomoon.svg';
import ErrorBoundary from 'EstruturaAntiga/errorBoundary';
import { ConfigProvider } from 'antd';
import GlobalTheme from 'GlobalTheme';
import ptBr from 'antd/locale/pt_BR';
import AppRoutes from 'routers/AppRoutes';
import AuthenticationService from 'context/AuthenticationService';
import { NotificationContainer } from 'react-notifications';

class App extends Component {
	state = {
		content: [],
		authorized: false
	}
	ObterTermosAceite = async () =>{
		let loadingTermosName = 'Termos de aceite pendentes';
		this.props.setLoadingItem(loadingTermosName);
		await getListarTermosParaAceite()
		.then((termos) => {
			this.props.removeLoadingItem(loadingTermosName);
			if (termos.length){
				this.props.getTermos(termos)
			}
			this.setState({ content: this.content(), authorized: true });
		})
		.catch((error) => {
				this.props.removeLoadingItem(loadingTermosName);
				this.setState({ content: this.content(), authorized: false });
			});
	}

	TentarObterTermosAceite = async () =>{
		const [sucesso, _] = await AuthenticationService.AtualizarEmCacheDadosRelacionadosAoToken();
		if(sucesso){
			this.ObterTermosAceite();
		}else{
			this.setState({ content: this.content(), authorized: false });
		}
	}
	
	componentDidMount(){
		this.TentarObterTermosAceite();
	}

	content() {
		return (<AppRoutes/> );
	}

	render() {
		return (
			<div className="app">
				<ErrorBoundary>
					<ConfigProvider theme={GlobalTheme} locale={ptBr}>
						<NotificationContainer className="global-notification" />
						{this.state.content}
					</ConfigProvider>
				</ErrorBoundary>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => (bindActionCreators({ getTermos, setLoadingItem, removeLoadingItem, errorPage }, dispatch))
export default connect(null, mapDispatchToProps)(App);