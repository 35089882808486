import React, { useEffect, useState } from 'react';
import Dropdown from '../../../../assets/icons/arrow_down.svg';
import { useAuth } from 'context/AuthContext';

const Signature = () => {
  const {direito, tenants, loading, AtualizarTenantAtual} = useAuth()
  const [outrosTenants, setOutrosTenants] = useState([]);
  const [showSignatureList, setShowSignatureList] = useState(false);
  
  useEffect(()=>{
    if(tenants == null || direito == null) return;
    const filtroOutrosTenants = tenants.filter(signature =>
      signature.tenantCode !== direito.tenantCode || signature.entitlementId !== direito.entitlementId);

    setOutrosTenants(filtroOutrosTenants);
  },[tenants, direito])

  const onClickChangeSignatureShow = e => {
    if (e.screenX < 768) {
      setShowSignatureList(!showSignatureList)
    }
  }
  return (
    <div
      className="header-signature"
      onClick={e => onClickChangeSignatureShow(e)}
      onMouseEnter={() => setShowSignatureList(true)}
      onMouseLeave={() => setShowSignatureList(false)}
    >
      <strong>{direito?.serviceLineName}</strong>
      <small>{direito?.tenantName}</small>

      <img className="dropdown" src={Dropdown} alt="Dropdown" />

      {
        !!showSignatureList && (
          <div className="header-signature-list">
            <h5>Alterar assinatura</h5>
            {
              loading  ? (
                <div className="is_loading">
                  <svg className="spinner" width="32px" height="32px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                  </svg>
                </div>
              ) : (
                outrosTenants.map(signature => (
                  <div
                    key={`${signature.serviceLineName}_${signature.tenantName}`}
                    onClick={async () => AtualizarTenantAtual(signature.entitlementId, signature.tenantCode)}
                    className="header-signature-item"
                  >
                    <span>{signature.serviceLineName}</span>
                    <small>{signature.tenantName}</small>
                  </div>
                ))
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default Signature