import { message } from "antd";
import DicaFeedbackDiv from "components/Roteiro/DicaFeedbackDiv";
import PesquisaFeedbackModal from "components/Roteiro/PesquisaFeedbackModal/PesquisaFeedbackModal";
import TourRoteiro from "components/Roteiro/TourRoteiro/TourRoteiro";
import React, { Children, createContext, useContext, useEffect, useState } from "react";
import RoteirosService from "services/RoteirosService";
import { useMenu } from "./MenuContext";
import { useAuth } from "./AuthContext";

const RoteiroContext = createContext();

export const useRoteiro = () =>{
    return useContext(RoteiroContext);
}

export const RoteiroProvider = ({children}) =>{
    const [objetosVisuais,setObjetosVisuais] = useState([]);
    const [roteirosDisponiveis, setRoteirosDisponiveis] = useState(false);
    const [roteiroAtual, setRoteiroAtual] = useState({});
    const [feedbackModalVisivel, setFeedbackModalVisivel] = useState(false);
    const [dicaFeedbackVisivel, setDicaFeedbackVisivel] = useState(false);
    const [tourVisivel, setTourVisivel] = useState(false);
    const [tourSteps, setTourSteps] = useState([]);
    const [resetFeedbackModal, setResetFeedbackModal] = useState(false);
    const [exibirRoteiros, setExibirRoteiros] = useState(false);
    const [indiceStepAtualDoTour, setIndiceStepAtualDoTour] = useState(0);
    const {authorized} = useAuth();
    const { menuVisivel, setMenuVisivel } = useMenu();

    const TIPOS_ROTEIRO = Object.freeze({
      ADOCAO: 321770000,
      PESQUISA: 321770001
    });
    
    const TIPOS_DICA = Object.freeze({
      BALAO: 321770000,
      POPUP_CENTRAL: 321770001
    });

    const TIPOS_OBJETO_VISUAL = Object.freeze({
      MENU: 321770000
    });

    useEffect(()=>{
      verificarExistenciaDeRoteiros();
    },[])

    useEffect(()=>{
      verificarExistenciaDeRoteiros();
    },[objetosVisuais])
    
    useEffect(()=>{
      if(authorized){
        const roteirosCarregados = JSON.parse(sessionStorage.getItem('roteiros'));
        if(roteirosCarregados) return;
        async function fetch(){
          const response = await RoteirosService.ListarRoteiros();
          if (response && response.length > 0) {
            sessionStorage.setItem('roteiros', JSON.stringify(response));
            setRoteirosDisponiveis(true);
          }
        }
        fetch();
      }
    }, [authorized])

    useEffect(()=>{
        if(roteirosDisponiveis){
          ProcessarRoteiros();
        }
    },[roteirosDisponiveis])

    const verificarExistenciaDeRoteiros = () => {
      const roteirosCarregados = JSON.parse(sessionStorage.getItem('roteiros')) || [];
      if(roteirosCarregados && roteirosCarregados.length > 0){
        setRoteirosDisponiveis(true);
      } else {
        const roteirosPendentes = JSON.parse(sessionStorage.getItem('roteirosPendentes')) || [];
        if(roteirosPendentes && roteirosPendentes.length > 0){
          sessionStorage.setItem('roteiros', JSON.stringify(roteirosPendentes));
          sessionStorage.removeItem('roteirosPendentes');
          setRoteirosDisponiveis(true);
        }
      }
    };

    function setReferenciaObjetosVisuais(novosObjetosVisuais) {
      setObjetosVisuais(prevObjetosVisuais => {
        const objetosAtualizados = prevObjetosVisuais.map(objetoExistente => {
          const novoObjeto = novosObjetosVisuais.find(novo => novo.id === objetoExistente.id && novo.ref.current !== null);
          return novoObjeto ? { ...objetoExistente, ref: novoObjeto.ref } : objetoExistente;
        });
    
        const objetosParaAdicionar = novosObjetosVisuais.filter(
          novoObjeto => !prevObjetosVisuais.some(objetoExistente => objetoExistente.id === novoObjeto.id) && novoObjeto.ref.current !== null
        );
    
        return [...objetosAtualizados, ...objetosParaAdicionar];
      });
    }
    

    const ProcessarRoteiros = () => {
      const roteiros = JSON.parse(sessionStorage.getItem('roteiros')) || [];

      if (roteiros && roteiros?.length > 0) {
        const roteiro = roteiros[0];
        setRoteiroAtual(roteiro);
        ProcessarRoteiro(roteiro);
        setExibirRoteiros(true);
      } else {
        setExibirRoteiros(false);
        setRoteirosDisponiveis(false);
      }
    };
    
    const ProcessarRoteiro = (roteiro) => {
        if (roteiro?.tipo === TIPOS_ROTEIRO.PESQUISA) {
            setFeedbackModalVisivel(true);
        } else if (roteiro?.tipo === TIPOS_ROTEIRO.ADOCAO && roteiro.dicas.length > 0) {
            configurarTour(roteiro.dicas);
        }
    }

    const avancarParaProximoRoteiro = (roteiroAtualExibido) => {
      const roteiros = JSON.parse(sessionStorage.getItem('roteiros'));
      sessionStorage.setItem('roteiros', JSON.stringify(roteiros.splice(1)));
        if (!roteiroAtualExibido) {
          const roteirosPendentes = JSON.parse(sessionStorage.getItem('roteirosPendentes')) || [];
          roteirosPendentes.push(roteiros[0]);
          sessionStorage.setItem('roteirosPendentes', JSON.stringify(roteirosPendentes));
        }

      ProcessarRoteiros();
      };
    
    const configurarTour = (dicas) => {
      const tourSteps = dicas.map((dica, index) => {
        if (dica.tipo === TIPOS_DICA.BALAO) {
          const objetoVisual = objetosVisuais.find(objeto => objeto.id === dica.objetoVisual.id);
          if (objetoVisual && objetoVisual.ref.current) {
            const placement = window.innerWidth >= 768 ? "right" : "bottom";
            return {
              prevButtonProps: {
                onClick: () => setIndiceStepAtualDoTour(prevIndice => prevIndice - 1)
              },
              nextButtonProps: {
                onClick: () => setIndiceStepAtualDoTour(prevIndice => prevIndice + 1)
              },
              placement: placement,
              description: (
                <div>
                  <br/>
                  <div dangerouslySetInnerHTML={{ __html: dica.texto }} />
                  <br/>
                  {index === dicas.length - 1 && (
                    <DicaFeedbackDiv/>
                  )}
                </div>
              ),
              target: objetoVisual.ref.current
            };
          }
        } else if (dica.tipo === TIPOS_DICA.POPUP_CENTRAL) {
          return {
            prevButtonProps: {
              onClick: () => setIndiceStepAtualDoTour(prevIndice => prevIndice - 1)
            },
            nextButtonProps: {
              onClick: () => setIndiceStepAtualDoTour(prevIndice => prevIndice + 1)
            },
            placement: "center",
            cover: (
              <iframe src={dica.link} title={dica.nome} style={{ width: '1000px', height: '400px' }} />
            ),
            description: (
              <div>
                {index === dicas.length - 1 && (
                  <DicaFeedbackDiv/>
                )}
              </div>
            )
          };
        }
        return null;
      }).filter(step => step !== null);

      if (tourSteps.length > 0 && tourSteps.length === dicas.length) {
        if (!menuVisivel && dicas.some(dica => dica.objetoVisual?.tipo === TIPOS_OBJETO_VISUAL.MENU)) {
          setMenuVisivel(true);
        } else if (menuVisivel && !dicas.some(dica => dica.objetoVisual?.tipo === TIPOS_OBJETO_VISUAL.MENU)) {
          setMenuVisivel(false);
        }
        setTourSteps(tourSteps);
        setTimeout(() => {
          setTourVisivel(true);
        }, 500);
        setDicaFeedbackVisivel(true);
      } else {
        avancarParaProximoRoteiro(false);
      }
    };
  
    const handleFecharFeedbackModal = () => {
      setFeedbackModalVisivel(false);
      setResetFeedbackModal(true);
      setTimeout(() => {
        avancarParaProximoRoteiro(true);
      }, 500);
    };
  
    const handleFecharTour = () => {
      setTourVisivel(false);
      setTimeout(() => {
        avancarParaProximoRoteiro(true);
      }, 500);
    };
  
    const handleEnviarPesquisa = async (nota, comentario) => {
      if (nota === 0) {
        message.error('Por favor, selecione uma nota.');
        return;
      }
  
      const audiencia = {
        RoteiroPortalClienteId: roteiroAtual?.roteiroPortalClienteId,
        Nota: nota,
        Comentario: comentario,
      };

      RoteirosService.CriarAudiencia(audiencia);
      handleFecharFeedbackModal();
    };
  
    const handleNaoQueroResponder = async () => {
      const audiencia = {
        RoteiroPortalClienteId: roteiroAtual?.roteiroPortalClienteId,
      };
      RoteirosService.CriarAudiencia(audiencia);
      handleFecharFeedbackModal();
    };
  
    const handleJoinha = async (nota) => {
      setDicaFeedbackVisivel(false);
      const audiencia = {
        RoteiroPortalClienteId: roteiroAtual?.roteiroPortalClienteId,
        Nota: nota,
      };
      RoteirosService.CriarAudiencia(audiencia);
    };

    return(
        <RoteiroContext.Provider 
          value={{
            setReferenciaObjetosVisuais,
            handleJoinha,
            dicaFeedbackVisivel,
            tourVisivel,
            handleFecharTour,
            tourSteps,
            indiceStepAtualDoTour,
            setIndiceStepAtualDoTour}}>
            {children}
            {exibirRoteiros && (
            <div>
              <PesquisaFeedbackModal
                titulo={roteiroAtual?.pergunta}
                rotuloMenorResposta={roteiroAtual?.rotuloRespostaMenorValor}
                rotuloMaiorResposta={roteiroAtual?.rotuloRespostaMaiorValor}
                abrirModal={feedbackModalVisivel}
                handleEnviar={handleEnviarPesquisa}
                handleCancelar={handleFecharFeedbackModal}
                handleNaoQueroResponder={handleNaoQueroResponder}
                resetModal={resetFeedbackModal}
                onResetCompleto={() => setResetFeedbackModal(false)}
              />

              <TourRoteiro />
            </div>
          )}
        </RoteiroContext.Provider>
    )
}
