import React, { Component } from 'react'
import MasterPage from "../../masterpages/errorPage"
import { useAuth } from 'context/AuthContext';

 const Error401 = () => {
    const { logout } = useAuth();
    return (
        <MasterPage classContainer="error-401" error="401" title="Acesso negado" text="O seu usuário não possui perfil de acesso definido. Entre em contato com o gestor da sua empresa.">
            <button className="btn-error" onClick={ () => logout() }><i className="icon-arrow-left"></i>Sair e se conectar com outro login</button>
        </MasterPage>
    );
}

export default Error401;