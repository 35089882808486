import React, { Component } from 'react'
import MasterPage from '../../masterpages/simpleAuthenticated'
import Termo from '../../components/termosDeAceite/TermosDeAceite'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

class TermosDeAceite extends Component {
    render() {
        if(this.props?.listaTermos.length > 0){
            return (
                <MasterPage>
                    <h1>Termos de aceite</h1>
                        
                    <div className="container-termos">
                        { this.props.listaTermos.map( (el) => <Termo key={ el.id } title={ el.titulo } id={ el.id } /> ) }
                    </div>
                </MasterPage>
            );
        }else{
            return(
                <Redirect to="/dashboard"/>
            )
        }

    };
}

export default connect(state => ({ listaTermos: state.termos.listaTermos }))(TermosDeAceite);