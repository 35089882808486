import { useAuth } from "context/AuthContext"
import { Redirect, Route } from "react-router-dom"

const PublicRoute = ({children, ...rest}) =>{
    const { authorized } = useAuth();
    return <Route {...rest} render={
        ({location})=>
            !authorized ? (children) :
            ( <Redirect to={{ pathname:"/dashboard", state: {from: location} }} />)
        }
    />
}

export default PublicRoute