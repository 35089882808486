export const mapSessionStorageTenant = (tenant)=>{
    return {
        tenantType: tenant.axt_tenanttype,
        tenantCode: tenant.axt_tenantcode,
        tenantName: tenant.axt_displayname,
        isShared: tenant.axt_isshared,
        modeloOferta: tenant.axt_modelodeoferta,
        formId: tenant._axt_adx_entityform_value,
        versaoPortal: tenant.axt_versaodoportal
    }
}