import React from 'react';

import MasterPage from '../../masterpages/authenticated';
import ContentBox from '../../../components/ContentBox';

import { Report } from '../../components/bi/Report';

export default function Atendimento () {
  return (
    <MasterPage useFullWidth={false}>
      <ContentBox className="bi-content">
        <Report
          reportName="Atendimento"
        />
      </ContentBox>
    </MasterPage>
  );
}
