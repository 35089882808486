import PortalClienteService from "services/bases/PortalClienteService";

class ContatosService extends PortalClienteService{
    async ListarPorDireito(direitoid){
        return await this.get(`contatos/ListarContatosPorDireito?direitoId=${direitoid}`)
    }
    async Listar(pagina = 1, tamanhoPagina = 20 , filtro = ''){
        return await this.get(`contatos?pagina=${pagina}&tamanhoPagina=${tamanhoPagina}&palavrachave=${filtro}`)
    }

}

export default new ContatosService();