import { useAuth } from "context/AuthContext"
import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom"

const PrivateRoute = ({children, ...rest}) =>{
    const { authorized } = useAuth();

    useEffect(()=>{
    },[])

    return <Route {...rest} render={
        ()=>
            authorized ? (children) : ( <Redirect to={{ pathname:"/login" }} /> )
        }
    />
}

export default PrivateRoute