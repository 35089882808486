import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: 'common',
    clientId: CONSTANTES_AMBIENTE.CONFIGURACOES.PORTAL_CLIENTE_APPID,
    endpoints: {
        api: CONSTANTES_AMBIENTE.CONFIGURACOES.PORTAL_CLIENTE_APPID,
    },
    cacheLocation: 'sessionStorage',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin + '/dashboard'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
