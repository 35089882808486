import { useEffect, useState, useRef } from 'react';
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import { Button, Table, Pagination, Flex, Input, Empty } from "antd";
import { LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined, DollarOutlined,  UnlockOutlined } from '@ant-design/icons';
import LoadingOrErrorPanel from 'components/LoadingOrErrorPanel';
import UsuariosService from 'services/UsuariosService';
import getUsuarioStatusCode from 'EstruturaAntiga/actions/usuarios/getUsuarioStatusCode';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';
import postUsuarioObterProduto from 'EstruturaAntiga/actions/usuarios/postUsuarioObterProduto';
import { withRouter } from 'react-router-dom';
import { rdxSetEditUser } from 'EstruturaAntiga/pages/usuariosV2/redux/usuariosActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AlterarSenha from 'EstruturaAntiga/pages/usuariosV2/componentes/AlterarSenha';
import getUsuario from 'EstruturaAntiga/actions/usuarios/getUsuario';
import { mapUserFieldsV2toV1 } from 'EstruturaAntiga/assets/userHelpers';
import AssessoresService from 'services/AssessoresService';
import { NotificationManager } from 'react-notifications';
import LoadingShadow from 'EstruturaAntiga/components/loadingShadow';
import DeleteUser from 'EstruturaAntiga/pages/usuariosV2/componentes/deleteUser';
import ModalAdvisorXpBlue3CannotBeDisabled from 'EstruturaAntiga/pages/usuariosV2/componentes/ModalAdvisorXpBlue3CannotBeDisabled';
import { profilesV2 } from 'EstruturaAntiga/permissions/permissionsV2';
import validateManagerProfileUnlink from 'EstruturaAntiga/actions/usuarios/validateManagerProfileUnlink';
import { getFormId } from 'EstruturaAntiga/pages/usuariosV2/componentes/Uteis';
import ObjectUtils from 'utils/objects';
import ArrayUtils from 'utils/arrays';
import LayoutUtils from 'utils/layout';
import { useWindowSize } from 'store/useWindowSize';
import { usePagination } from 'store/usePagination';
import { STATUS_CODE } from 'constants/statusCodes';
import { useRoteiro } from 'context/RoteiroContext';

function ListagemUsuarios({ history, rdxSetEditUser }) {
    const [desabilitarFuncoesUsuario, setDesabilitarFuncoesUsuario] = useState(true);
    const [erro, setErro] = useState({temErro: false, mensagens: ''});
    const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
    const [statusUsuarios, setStatusUsuarios] = useState([]);
    const [produtosUsuarios, setProdutosUsuarios] = useState([]);
    const [carregandoStatusUsuarios, setCarregandoStatusUsuarios] = useState(true);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState({});
    const [modal, setModal] = useState('');
    const { Search } = Input;
    const {alturaTotalTela} = useWindowSize();
    const {setReferenciaObjetosVisuais} = useRoteiro();
    const refBotaoNovoUsuario = useRef(null);
    const refTabelaUsuarios = useRef(null);
    const refFiltroUsuarios = useRef(null);
    const refPaginacaoUsuarios = useRef(null);
    const refBotoesUsuarios = useRef(null);

    const refs = [
        { id: 'refBotaoNovoUsuario', ref: refBotaoNovoUsuario },
        { id: 'refFiltroUsuarios', ref: refFiltroUsuarios },
        { id: 'refTabelaUsuarios', ref: refTabelaUsuarios },
        { id: 'refPaginacaoUsuarios', ref: refPaginacaoUsuarios },
        { id: 'refBotoesUsuarios', ref: refBotoesUsuarios }
    ];

    const listarUsuarios = async (paginaAtual = 1, tamanhoPagina = 10, filtro = '', skipToken = '') =>{
        const response = await UsuariosService.NovoListarUsuarios(skipToken, filtro, tamanhoPagina);
        if(!response) return;
    
        response.users.forEach(usuario => {
            usuario.status = <LoadingOutlined />;
            usuario.plano = <LoadingOutlined />;
        });
    
        return response;
    }

    const {
        dados: usuarios,
        handleAlteracaoDePaginacao,
        executarFetchData,
        paginacaoLoading,
        paginacaoErro,
        paginacaoOpcoes,
        filtroAplicado,
        setDados: setUsuarios,
        setPaginasArmazenadas,
        handleFiltro,
        setPaginacaoLoading,
        resetarPaginacao
    } = usePagination( listarUsuarios, 'users', false );

    const LayoutHeights = {
        header: 92,
        footer: 90,
        containerPadding: 32,
        innerHeader: 60,
        tableTitle: 60
    }

    useEffect(()=>{
        inicializarListagem();
    }, []);

    useEffect(()=>{
        if (!paginacaoLoading){
            carregarStatusUsuarios();
        }
    }, [paginacaoLoading]);

    useEffect(()=>{
        atualizarStatusUsuarios();
        atualizarPaginasArmazenadasComNovosStatus();
    }, [statusUsuarios]);

    useEffect(()=>{
        if (!carregandoStatusUsuarios){
            carregarProdutosUsuarios();
        }
    }, [carregandoStatusUsuarios, paginacaoOpcoes]);

    useEffect(()=>{
        atribuirPlanosUsuariosNoStateUsuarios();
        atribuirPlanosUsuariosNoStatePaginasArmazenadas();
    }, [produtosUsuarios]);

    useEffect(()=>{
        desabilitarAcoesUsuario();
    }, [usuarios, usuarioSelecionado]);

    const inicializarListagem = async () => {
        if (!await validarSeGestaoUsuariosEstaHabilitada()) return;
        await executarFetchData();
        setReferenciaObjetosVisuais(refs);
    }

    const validarSeGestaoUsuariosEstaHabilitada = async () => {
        await UsuariosService.ValidarHabilitacaoGestaoUsuarios()
            .catch(error =>{
                setErro({
                    temErro: true, 
                    mensagens: error.response?.data?.erros ?? 'Erro inesperado ao obter direito para verificar se a gestão de usuários está habilitada.'
                });
                setPaginacaoLoading(false);
                return false;
            });
        return true;
    }

    const rowSelectionOnChange = (newSelectedRowKeys, selectedRows) => {
        setLinhasSelecionadas(newSelectedRowKeys);
        setUsuarioSelecionado(selectedRows[0]);
    };

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: linhasSelecionadas,
        onChange: rowSelectionOnChange
    };

    const colunasTabelaUsuario = [
        {
            title: 'Nome',
            dataIndex: 'displayName',
            key: 'displayName',
            width: '100px',
            ellipsis: true
        },
        {
            title: 'Login',
            dataIndex: 'userPrincipalName',
            key: 'userPrincipalName',
            width: '200px',
            ellipsis: true
        },
        {
            title: 'E-mail',
            dataIndex: 'mail',
            key: 'mail',
            width: '200px',
            ellipsis: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '50px',
            ellipsis: true
        },
        {
            title: 'Plano',
            dataIndex: 'plano',
            key: 'plano',
            width: '100px',
            ellipsis: true
        }
    ];

    const paginacaoOnChange = (paginaAtual, tamanhoPagina) =>{
        setLinhasSelecionadas([]);
        setUsuarioSelecionado({});
        handleAlteracaoDePaginacao(paginaAtual, tamanhoPagina);
    }

    const onSearch = (valorFiltro) =>{
        setLinhasSelecionadas([]);
        setUsuarioSelecionado({});
        handleFiltro(valorFiltro);
    }

    const carregarStatusUsuarios = async () => {
        setCarregandoStatusUsuarios(true);

        const lotesUsuarios = ArrayUtils.splitIntoBatches(usuarios, 10);
        for (const lote of lotesUsuarios) {
            const resultados = await Promise.all(
                lote.map(async (usuario) => {
                    if (!statusUsuarios.some(x => x.axt_idobjetousuario365 === usuario.id)) {
                        const resultado = await getUsuarioStatusCode(usuario.id);
                        if (!resultado) return null;
                        if (resultado?.statuscode === 204) {
                            resultado.axt_idobjetousuario365 = usuario.id;
                        }
                        return resultado;
                    }
                    return null;
                })
            );

            const novosStatus = resultados.filter(status => status !== null);
    
            setStatusUsuarios(prevStatusUsuarios => [...prevStatusUsuarios, ...novosStatus]);
        }
        setCarregandoStatusUsuarios(false);
    };

    const carregarProdutosUsuarios = async () => {
        const lotesUsuarios = ArrayUtils.splitIntoBatches(usuarios, 10);
        for (const lote of lotesUsuarios) {
            const resultados = await Promise.all(
                lote.map(async (usuario) => {
                    if (!produtosUsuarios.some(x => x.usuarioId === usuario.id)) {
                        const userProduct = await postUsuarioObterProduto(usuario.assignedLicenses);
                        return { produtos: userProduct, usuarioId: usuario.id };
                    }
                    return null;
                })
            );

            const novosProdutos = resultados.filter(produto => produto !== null);

            setProdutosUsuarios(prevProdutosUsuarios => [...prevProdutosUsuarios, ...novosProdutos]);
        }
    };

    const obterTextoStatusUsuario = (usuarioDesativado, razaoStatus) => {
		if(usuarioDesativado && razaoStatus != CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.EM_SINCRONIZACAO){
            return CONSTANTES_AMBIENTE.ARRAY_STATUS_USUARIOS.filter(status => status.code == CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.DESATIVADO)[0].status;
        }
        return razaoStatus ? CONSTANTES_AMBIENTE.ARRAY_STATUS_USUARIOS.filter(status => status.code == razaoStatus)[0].status : '';
	}

    const atribuirStatusUsuarios = (usuarios) => {
        return usuarios.map((usuario) => {
            const statusUsuario = statusUsuarios.find(x => x.axt_idobjetousuario365 === usuario.id);
            if (!statusUsuario) return usuario;

            return { ...usuario, status: obterTextoStatusUsuario(!usuario.accountEnabled, statusUsuario?.statuscode)};
        });
    }
    
    const atualizarStatusUsuarios = async () => {
        if (statusUsuarios.length <= 0) return;

        const novosDados = atribuirStatusUsuarios(usuarios);
        setUsuarios(novosDados);
    }

    const atualizarPaginasArmazenadasComNovosStatus = async () => {
        if (statusUsuarios.length <= 0) return;

        setPaginasArmazenadas((prevPaginasArmazenadas) => {
          const novoCache = Object.fromEntries(
            Object.entries(prevPaginasArmazenadas).map(([pagina, usuarios]) => [
              pagina,
              atribuirStatusUsuarios(usuarios),
            ])
          );     
          return novoCache;
        });
    };

    const atribuirPlanosUsuarios = (usuarios) => {
        return usuarios.map((usuario) => {
            const produtosUsuario = produtosUsuarios.find(x => x.usuarioId === usuario.id);
            if (!produtosUsuario) return usuario;
    
            const planoPrincipal = produtosUsuario?.produtos?.find(produto => produto.estruturaProduto === 3)?.produto;
            return { ...usuario, plano: planoPrincipal ? planoPrincipal : '' };
        });
    }

    const atribuirPlanosUsuariosNoStateUsuarios = async () => {
        if (produtosUsuarios.length <= 0) return;

        const novosDados = atribuirPlanosUsuarios(usuarios)
        setUsuarios(novosDados);
    }

    const atribuirPlanosUsuariosNoStatePaginasArmazenadas = async () => {
        if (produtosUsuarios.length <= 0) return;

        setPaginasArmazenadas((prevPaginasArmazenadas) => {
          const novoCache = Object.fromEntries(
            Object.entries(prevPaginasArmazenadas).map(([pagina, usuarios]) => [
              pagina,
              atribuirPlanosUsuarios(usuarios)
            ])
          );     
          return novoCache;
        });
    };

    const reduxSetEditarUsuario = () => {
        const dadosEdicao = {
            contact: usuarioSelecionado,
            displayName: usuarioSelecionado.displayName,
            mail: usuarioSelecionado.mail,
            userPrincipalName: usuarioSelecionado.userPrincipalName,
            accountEnabled: usuarioSelecionado.accountEnabled,
            isSync: usuarioSelecionado.axt_onpremisessyncenabled,
            key: usuarioSelecionado.userPrincipalName,
            usuarioId: usuarioSelecionado.id
        }
        rdxSetEditUser(dadosEdicao);
    }

    const onClickEditarUsuario = () => {
        if (desabilitarFuncoesUsuario) {
            return;
        }
        reduxSetEditarUsuario();
        history.push(`/usuarios/editar`);
    }

    const onClickAlterarPlano = () => {
        if (desabilitarFuncoesUsuario) {
            return;
        }
        reduxSetEditarUsuario();
        history.push(`/usuarios/alterarPlano`);
    }

    const onCloseModal = () => {
		setModal('');
	}

    const onCloseModalExcluirUsuario = async (deletedUser = false) => {
        if(deletedUser){
            setLinhasSelecionadas([]);
            setUsuarioSelecionado({});
            resetarPaginacao();
			executarFetchData();
		}
		onCloseModal();
	}

    const onClickAlterarSenha = () => {
        if (desabilitarFuncoesUsuario) {
            return;
        }
        setModal('alterar-senha');
    }

    const excluirUsuario = async () => {
        const userMail = usuarioSelecionado.mail || usuarioSelecionado.userPrincipalName;

        const perfisAcesso = await UsuariosService.ListarPerfisDeAcesso(usuarioSelecionado.id);

        let canRemoveUser = true;
        let validateProfiles = [];
    
        perfisAcesso.forEach((perfil) => {
            let entitlementId = perfil.direitoId;
            let perfilDeAcesso = perfil.perfilDeAcessoCodigo;

            if (perfilDeAcesso == profilesV2.gestorContrato.idAxtRole) {
                validateProfiles.push(
                    validateManagerProfileUnlink(userMail, entitlementId)
                );
            }
        });
    
        Promise.all(validateProfiles).then((obj) => {
            obj.forEach((result) => {
            if (!result.hasMoreThanOneManagerContracts) {
                onCloseModal();                
                NotificationManager.error(
                    `Defina outro usuário como Gestor de Contrato para a linha de serviço ${result.serviceLineName} antes de prosseguir.`,
                    "Não foi possível excluir este usuário.",
                    9000
                );
                canRemoveUser = false;
            }
            });
    
            if (canRemoveUser) {
                setModal('excluir-usuario')
            }
        });
    }

    const onClickExcluirUsuario = async () => {
        if (desabilitarFuncoesUsuario) {
            return;
        }
        setModal('shadow');

		if (getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.XP || getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.BLUE3) {
			const user = await getUsuario(usuarioSelecionado.id)
								.then(obj => mapUserFieldsV2toV1(obj));
			let advisorUserCode = user?.axt_extensionattribute5;

			if (advisorUserCode){
				AssessoresService.PodeSerDespadronizado(advisorUserCode)
				.then((response) => {
				if (response && response.canBeDisabled) {
					excluirUsuario();
				} else {
                    setModal('alert-advisor-xp-cannot-be-disabled');
				}
				})
				.catch(() => {
					onCloseModal();
					NotificationManager.error(
						"Favor tentar novamente ou entre em contato com seu gestor.",
						"Não foi possivel excluir o usuário!",
						7000
					);
				});
			} else {
				excluirUsuario();
			}
		} else {
			excluirUsuario();
		}
	}

    const renderModal = () => {
        if (modal === 'shadow') {
            return (
                <LoadingShadow
                    sizeClassName={''}
                    open={true} 
                    close={() => {}}
                />
            )
        } else if (modal === 'alterar-senha') {
            return (
                <AlterarSenha
                    onCloseModal={onCloseModal.bind(this)}
                    upn={usuarioSelecionado.userPrincipalName || usuarioSelecionado.mail}
                    sync={usuarioSelecionado.axt_onpremisessyncenabled || false}
                />
            )
        } else if (modal === "excluir-usuario") {
            return (
                <DeleteUser
                    onCloseModal={onCloseModalExcluirUsuario.bind(this)}
                    userId={usuarioSelecionado.id}
                />
            );
        } else if (modal === "alert-advisor-xp-cannot-be-disabled") {
            return (
                <ModalAdvisorXpBlue3CannotBeDisabled onCloseModal={onCloseModal.bind(this)}/>
            );
        }
    }

    const desabilitarAcoesUsuario = () => {
        if (ObjectUtils.ObjetoEhVazio(usuarioSelecionado)) {
            return setDesabilitarFuncoesUsuario(true);
        }
    
        const statusUsuario = statusUsuarios.find(x => x.axt_idobjetousuario365 === usuarioSelecionado?.id);
        if (!statusUsuario || !usuarioSelecionado?.accountEnabled || 
            (statusUsuario?.statuscode !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && 
            statusUsuario?.statuscode !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO)) {
                return setDesabilitarFuncoesUsuario(true);
        }
    
        setDesabilitarFuncoesUsuario(false);
    };

    const retornarMensagemErro = () => {
        if (paginacaoErro.temErro || erro.temErro) {
            if (paginacaoErro.statuscode === STATUS_CODE.UNAUTHORIZED || paginacaoErro.statuscode === STATUS_CODE.FORBIDDEN) {
                return 'O Portal do Cliente não possui o consentimento de seu administrador para listar os usuários.';
            }
            if (erro.mensagens) {
                return erro.mensagens;
            }
            if (paginacaoErro.mensagens) {
                return paginacaoErro.mensagens;
            }
        }
        return '';
    };

    return (
        <MasterPage useFullWidth={!paginacaoErro.temErro && !erro.temErro}>
            <LoadingOrErrorPanel nomeMenu={'Usuários'} loading={paginacaoLoading} hasError={paginacaoErro.temErro || erro.temErro} mensagemErro={retornarMensagemErro()}>
                {renderModal()}
                <Flex
                    justify='flex-start'
                    align='center'
                    style={{ height: '40px', background: '#f8f9fa', boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", margin: "10px 0", borderRadius:"5px", padding:"3px 10px" }}
                >
                    <div ref={refBotoesUsuarios} style={{ width:'fit-content' }}>
                        <Button type="text" onClick={() => history.push('/usuarios/novo')} style={{ marginRight: 8 }} ref={refBotaoNovoUsuario}>
                            <PlusOutlined />Novo usuário
                        </Button>
                        <Button type="text" disabled={desabilitarFuncoesUsuario} onClick={onClickEditarUsuario} style={{ marginRight: 8 }}>
                            <EditOutlined />Editar
                        </Button>
                        <Button type="text" disabled={desabilitarFuncoesUsuario} onClick={onClickAlterarSenha}  style={{ marginRight: 8 }}>
                            <UnlockOutlined />Redefinir senha
                        </Button>
                        <Button type="text" disabled={desabilitarFuncoesUsuario} onClick={onClickAlterarPlano} style={{ marginRight: 8 }}>
                            <DollarOutlined />Alterar plano
                        </Button>
                        <Button type="text" danger disabled={desabilitarFuncoesUsuario} onClick={onClickExcluirUsuario}>
                            <DeleteOutlined />Excluir
                        </Button>
                    </div>
                </Flex>
                <div ref={refTabelaUsuarios}>
                    <Table
                        rowKey={'id'}
                        dataSource={usuarios}
                        columns={colunasTabelaUsuario}
                        rowSelection={rowSelection} 
                        pagination={false}
                        size='small'
                        scroll={ usuarios.length > 0 ? { y: LayoutUtils.ObterAlturaDisponivelEmTela(Object.values(LayoutHeights), alturaTotalTela) } : undefined }
                        bordered={false}
                        locale={{ emptyText: <Empty description={'Nenhum usuário encontrado'} image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                        onRow={(record) => {
                            return {
                                onClick: () => {rowSelectionOnChange([record.id], [record])},
                                onDoubleClick: () => {onClickEditarUsuario()}
                            };
                        }}
                        title={() =>
                            <Flex justify='space-between' align= 'center' style={{ height: '44px' }}>
                                <h3 style={{ fontSize: '28px' }}>Usuários</h3>
                                <div ref={refFiltroUsuarios} >
                                    <Search
                                        placeholder={"Filtrar por nome ou e-mail"}
                                        onSearch={onSearch}
                                        allowClear
                                        style={{width: 235}}
                                        defaultValue={filtroAplicado}
                                    />
                                </div>
                            </Flex>
                        }
                        footer={() => 
                            <div ref={refPaginacaoUsuarios} style={{ width:'fit-content' }}>
                                <Pagination 
                                    defaultCurrent={1}
                                    simple={{ readOnly: true }}
                                    total={paginacaoOpcoes.total}
                                    showTotal={(total) => `Total: ${total}`}
                                    pageSize={paginacaoOpcoes.tamanhoPagina}
                                    onChange={paginacaoOnChange}
                                    disabled={paginacaoLoading}
                                    showSizeChanger={paginacaoOpcoes.total > 10}
                                    current={paginacaoOpcoes.paginaAtual}
                                    locale={{ items_per_page: '/ página' }}
                                />
                            </div>
                        }
                    />
                </div>
            </LoadingOrErrorPanel>
        </MasterPage>
    );
}

export default withRouter(connect(
    state => ({ 
        user: state.users.user
    }),
    (dispatch) => (bindActionCreators({
        rdxSetEditUser,
    }, dispatch))
)(ListagemUsuarios));