import { createContext, useContext, useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';

import { authContext } from "EstruturaAntiga/adalConfig";
import Error401 from "EstruturaAntiga/pages/errorPages/401";
import PaginaErroNaoTratado from "EstruturaAntiga/pages/errorPages/paginaErroNaoTratado";
import Loading from "EstruturaAntiga/components/loading/Loading";
import Error404 from "EstruturaAntiga/pages/errorPages/404";
import Error503 from "EstruturaAntiga/pages/errorPages/503";

import UserFavoritePortalDbService from "services/UserFavoritePortalDbService";
import AuthenticationService from "./AuthenticationService";

const AuthContext = createContext();

export const AuthProvider = ({children, history}) => {
    const [user, setUser] = useState(null);
    const [tenants, setTenants] = useState([]);
    const [tenant, setTenant] = useState(null);
    const [contato, setContato] = useState(null);
    const [direito, setDireito] = useState(null);
    const [authorized, setAuthorized] = useState(false);    
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        Initialization();
    },[])

    const Initialization = async () => {
        authContextInstance.forceLogout = forceLogout;
        const cachedUser = ObterUsuarioAdEmCache();
        if(cachedUser){
            let [sucesso, authData, error] = await AuthenticationService.AtualizarEmCacheDadosRelacionadosAoToken();
            if(!sucesso){
                setLoading(false);
                return;
            }

            setUser(cachedUser)
            sucesso = await AtualizarDadosRelacionadosAoUsuario(authData);
            setLoading(false);
            
            if(!sucesso){
                return;
            }
            setAuthorized(true);
            AtualizarContatoComDadosPerfil(cachedUser);
        }
        else{
            setAuthorized(false)
            setLoading(false)
            clearCache();
            history.push("/login")
        }
    }
    const AtualizarDadosRelacionadosAoUsuario = async (authData) =>{
        const [contatoNovoSucesso, contatoNovo, contatoNovoError] = await AuthenticationService.AtualizarEmCacheContatoDoUsuario(authData);
        if(!contatoNovoSucesso){
            setError(contatoNovoError);
            return false;
        }
        setContato(contatoNovo);

        const [direitoAtualSucesso, direitoAtual, direitoAtualError] = await AuthenticationService.AtualizarEmCacheDireitoAtualDoUsuario();
        if(!direitoAtualSucesso){
            setError(direitoAtualError);
            return false;
        }
        setDireito(direitoAtual)

        const [listaTenantsSucesso, listaTenants, listaTenantsError] = await AuthenticationService.AtualizarEmCacheTenantsDoUsuario();
        if(!listaTenantsSucesso){
            setError(listaTenantsError);
            return false;
        }
        setTenants(listaTenants)
        
        const [tenantAtualSucesso, tenantAtual, tenantAtualError] = await AuthenticationService.AtualizarEmCacheTenantAtual(direitoAtual?.axtTenantId);
        if(!tenantAtualSucesso){
            setError(tenantAtualError);
            return false;
        }
        setTenant(tenantAtual)
        return true;
    }
    const AtualizarContatoComDadosPerfil = async (userData) =>{
        let [sucesso, novoContato, error] = await AuthenticationService.AtualizarEmCacheContatoComPerfilDoUsuario(userData)
        if(!sucesso){
            return false;
        }
        setContato(novoContato);
        return true;
    }

    const ObterUsuarioAdEmCache = ()=>{
        let cachedUser = authContext.getCachedUser();
        if(!cachedUser){
            authContext.handleWindowCallback();
            cachedUser = authContext.getCachedUser();
        }
        
        return cachedUser
    }

    const logout = () =>{
        authContext.clearCache();
        clearCache();
        setUser(null)
        setAuthorized(false)
        authContext.logOut()
    }
    const clearCache = () =>{
        sessionStorage.clear();
        localStorage.clear();
    }

    const forceLogout = () => {
        logout();
    };

    const login =() =>{
        authContext.login()
    }

    const AtualizarTenantAtual = async (entitlementId, tenantCode) =>{
        const data = {
            id: contato.idContact,
            entitlementId,
            tenantCode
        };
        setLoading(true);
        await UserFavoritePortalDbService.AtualizarFavorito(data)
            .then(async ()=>{
                let [direitoSucesso, direitoResponse, direitoError] = await AuthenticationService.AtualizarEmCacheDireitoAtualDoUsuario(true);
                if(!direitoSucesso){
                    setLoading(false)
                    return;
                }
                setDireito(direitoResponse);

                let [tenantSucesso, tenantResponse, tenantError] = await AuthenticationService.AtualizarEmCacheTenantAtual(direitoResponse?.axtTenantId, true)
                if(!tenantSucesso){
                    setLoading(false)
                    return;
                }
                setTenant(tenantResponse);
                setLoading(false);
                history.push("/dashboard")
            }).catch(error=>{
                setLoading(false);
                setError({statuscode: error?.response?.status || 500, msg: "Erro ao atualizar o Tenant Atual"})
            });
    }

    return(
        <AuthContext.Provider value={
            {
                login, 
                logout,
                AtualizarTenantAtual,
                Initialization,
                user, 
                loading, 
                tenants, 
                tenant, 
                contato, 
                direito,
                authorized,
            }}>
                {loading ? 
                    <Loading/> :
                        error != null ? 
                            error?.statuscode == 401 ? <Error401/> :
                            error?.statuscode == 404 ? <Error404/> : 
                            error?.statuscode == 503 ? <Error503/> :
                            <PaginaErroNaoTratado/>
                        : children
                }
        </AuthContext.Provider>
    )
}
export const useAuth = () => withRouter(useContext(AuthContext));

export const authContextInstance = {
    forceLogout: () => {
        throw new Error("AuthContext não foi inicializado corretamente!");
    },
};
