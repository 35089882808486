import React, { useEffect, useState } from 'react';
import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GetColorBySignature } from 'constants/contanstesEstilo';

import Signature from './components/Signature';
import UserNavigation from '../userNavigation/UserNavigation';
import Logo from '../../../assets/images/logo.svg';
import { ToggleButton } from 'EstruturaAntiga/masterpages/styles';
import { useMenu } from 'context/MenuContext';
import { useAuth } from 'context/AuthContext';

function Header(){
	const {menuVisivel, setMenuVisivel} = useMenu()
	const [color,setColor] = useState("")
	const {direito} = useAuth()
	useEffect(()=>{
		const signature = direito ? direito.serviceLineId.toLowerCase() : '';
		setColor(GetColorBySignature(signature))
	}, [direito])
	return (
		<header className="main-header" style={{ backgroundColor: color }}>
			<div className="header-content">
				<ToggleButton onClick={()=>setMenuVisivel(!menuVisivel)} className='nav-mobile'>
					{!menuVisivel ?  <MenuUnfoldOutlined />:  <CloseOutlined />}
				</ToggleButton>
				<Signature />
				<div className="action-container">
					<div className="rigth-conteiner">
						<UserNavigation />

						<Link to="/" className="logo" >
							<img src={Logo} alt="Página inicial - BHS Axter Portal do cliente" />
						</Link>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;